
import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from 'react-router-dom';


var data1 = [
    [1327359600000, 10.95],
    [1327446000000, 11.34],
    [1327532400000, 11.18],
    [1327618800000, 11.05],
    [1327878000000, 11.00],
    [1327964400000, 10.95],
    [1328050800000, 11.24],
    [1328137200000, 11.29],
    [1328223600000, 11.85],
    [1328482800000, 11.86],
    [1328569200000, 12.28],
    [1328655600000, 12.10],
    [1328742000000, 12.65],
    [1328828400000, 12.21],
    [1329087600000, 12.35],
    [1329174000000, 12.44],
    [1329260400000, 12.46],
    [1329346800000, 12.86],
    [1329433200000, 12.75],
    [1329778800000, 12.54],
    [1329865200000, 12.33],
    [1329951600000, 12.97],
    [1330038000000, 13.41],
    [1330297200000, 13.27],
    [1330383600000, 13.27],
    [1330470000000, 12.89],
    [1330556400000, 13.10],
    [1330642800000, 13.73],
    [1330902000000, 13.22],
    [1330988400000, 11.99],
    [1331074800000, 12.41],
    [1331161200000, 13.05],
    [1331247600000, 13.64],
    [1331506800000, 13.56],
    [1331593200000, 14.22],
    [1331679600000, 13.77],
    [1331766000000, 14.17],
    [1331852400000, 13.82],
    [1332111600000, 14.51],
    [1332198000000, 13.16],
    [1332284400000, 13.56],
    [1332370800000, 13.71],
    [1332457200000, 13.81],
    [1332712800000, 14.40],
    [1332799200000, 14.63],
    [1332885600000, 14.46],
    [1332972000000, 14.48],
    [1333058400000, 14.31],
    [1333317600000, 14.70],
    [1333404000000, 14.31],
    [1333490400000, 13.46],
    [1333576800000, 13.59],
    [1333922400000, 13.22],
    [1334008800000, 12.61],
    [1334095200000, 13.01],
    [1334181600000, 13.55],
    [1334268000000, 13.18],
    [1334527200000, 12.84],
    [1334613600000, 13.84],
    [1334700000000, 13.39],
    [1334786400000, 12.91],
    [1334872800000, 13.06],
    [1335132000000, 12.62],
    [1335218400000, 12.40],
    [1335304800000, 13.13],
    [1335391200000, 13.26],
    [1335477600000, 13.58],
    [1335736800000, 13.55],
    [1335823200000, 13.77],
    [1335909600000, 13.76],
    [1335996000000, 13.32],
    [1336082400000, 12.61],
    [1336341600000, 12.52],
    [1336428000000, 12.67],
    [1336514400000, 12.52],
    [1336600800000, 11.92],
    [1336687200000, 12.20],
    [1336946400000, 12.23],
    [1337032800000, 12.33],
    [1337119200000, 12.36],
    [1337205600000, 12.01],
    [1337292000000, 11.31],
    [1337551200000, 12.01],
    [1337637600000, 12.01],
    [1337724000000, 12.18],
    [1337810400000, 11.54],
    [1337896800000, 11.60],
    [1338242400000, 12.05],
    [1338328800000, 11.29],
    [1338415200000, 11.05],
    [1338501600000, 19.82],
    [1338760800000, 10.31],
    [1338847200000, 10.70],
    [1338933600000, 11.69],
    [1339020000000, 11.32],
    [1339106400000, 11.65],
    [1339365600000, 11.13],
    [1339452000000, 11.77],
    [1339538400000, 11.79],
    [1339624800000, 11.67],
    [1339711200000, 12.39],
    [1339970400000, 12.63],
    [1340056800000, 12.89],
    [1340143200000, 11.99],
    [1340229600000, 11.23],
    [1340316000000, 11.57],
    [1340575200000, 10.84],
    [1340661600000, 11.07],
    [1340748000000, 11.41],
    [1340834400000, 11.17],
    [1340920800000, 12.37],
    [1341180000000, 12.19],
    [1341266400000, 12.51],
    [1341439200000, 12.53],
    [1341525600000, 11.37],
    [1341784800000, 10.43],
    [1341871200000, 10.44],
    [1341957600000, 10.20],
    [1342044000000, 10.14],
    [1342130400000, 10.65],
    [1342389600000, 10.40],
    [1342476000000, 10.65],
    [1342562400000, 11.43],
    [1342648800000, 11.89],
    [1342735200000, 11.38],
    [1342994400000, 10.64],
    [1343080800000, 10.02],
    [1343167200000, 10.33],
    [1343253600000, 10.95],
    [1343340000000, 11.89],
    [1343599200000, 11.01],
    [1343685600000, 10.88],
    [1343772000000, 10.69],
    [1343858400000, 10.58],
    [1343944800000, 12.02],
    [1344204000000, 12.14],
    [1344290400000, 12.37],
    [1344376800000, 12.51],
    [1344463200000, 12.65],
    [1344549600000, 12.64],
    [1344808800000, 12.27],
    [1344895200000, 12.10],
    [1344981600000, 12.91],
    [1345068000000, 13.65],
    [1345154400000, 13.80],
    [1345413600000, 13.92],
    [1345500000000, 13.75],
    [1345586400000, 13.84],
    [1345672800000, 13.50],
    [1345759200000, 12.26],
    [1346018400000, 12.32],
    [1346104800000, 12.06],
    [1346191200000, 11.96],
    [1346277600000, 11.46],
    [1346364000000, 11.27],
    [1346709600000, 11.43],
    [1346796000000, 12.26],
    [1346882400000, 12.79],
    [1346968800000, 12.46],
    [1347228000000, 12.13],
    [1347314400000, 12.43],
    [1347400800000, 12.42],
    [1347487200000, 12.81],
    [1347573600000, 13.34],
    [1347832800000, 13.41],
    [1347919200000, 12.57],
    [1348005600000, 13.12],
    [1348092000000, 14.53],
    [1348178400000, 13.83],
    [1348437600000, 13.41],
    [1348524000000, 12.90],
    [1348610400000, 12.53],
    [1348696800000, 12.80],
    [1348783200000, 12.44],
    [1349042400000, 12.62],
    [1349128800000, 12.57],
    [1349215200000, 12.60],
    [1349301600000, 12.68],
    [1349388000000, 12.47],
    [1349647200000, 12.23],
    [1349733600000, 11.68],
    [1349820000000, 11.51],
    [1349906400000, 11.78],
    [1349992800000, 11.94],
    [1350252000000, 12.33],
    [1350338400000, 13.24],
    [1350424800000, 13.44],
    [1350511200000, 13.48],
    [1350597600000, 13.24],
    [1350856800000, 13.49],
    [1350943200000, 13.31],
    [1351029600000, 13.36],
    [1351116000000, 13.40],
    [1351202400000, 14.01],
    [1351638000000, 14.02],
    [1351724400000, 14.36],
    [1351810800000, 14.39],
    [1352070000000, 14.24],
    [1352156400000, 14.39],
    [1352242800000, 13.47],
    [1352329200000, 12.98],
    [1352415600000, 12.90],
    [1352674800000, 12.70],
    [1352761200000, 12.54],
    [1352847600000, 12.23],
    [1352934000000, 12.64],
    [1353020400000, 12.65],
    [1353279600000, 12.92],
    [1353366000000, 12.64],
    [1353452400000, 12.84],
    [1353625200000, 13.40],
    [1353884400000, 13.30],
    [1353970800000, 13.18],
    [1354057200000, 13.88],
    [1354143600000, 14.09],
    [1354230000000, 14.61],
    [1354489200000, 14.70],
    [1354575600000, 15.30],
    [1354662000000, 15.40],
    [1354748400000, 15.14],
    [1354834800000, 15.48],
    [1355094000000, 15.75],
    [1355180400000, 15.54],
    [1355266800000, 15.96],
    [1355353200000, 15.53],
    [1355439600000, 17.56],
    [1355698800000, 17.42],
    [1355785200000, 17.49],
    [1355871600000, 18.09],
    [1355958000000, 17.87],
    [1356044400000, 17.71],
    [1356303600000, 17.53],
    [1356476400000, 17.55],
    [1356562800000, 17.30],
    [1356649200000, 16.90],
    [1356908400000, 17.68],
    [1357081200000, 18.34],
    [1357167600000, 17.75],
    [1357254000000, 18.13],
    [1357513200000, 17.94],
    [1357599600000, 18.14],
    [1357686000000, 18.66],
    [1357772400000, 18.62],
    [1357858800000, 18.09],
    [1358118000000, 18.16],
    [1358204400000, 18.15],
    [1358290800000, 17.88],
    [1358377200000, 17.73],
    [1358463600000, 17.98],
    [1358809200000, 17.95],
    [1358895600000, 18.25],
    [1358982000000, 18.10],
    [1359068400000, 18.32],
    [1359327600000, 18.24],
    [1359414000000, 18.52],
    [1359500400000, 17.94],
    [1359586800000, 17.83],
    [1359673200000, 18.34],
    [1359932400000, 18.10],
    [1360018800000, 18.51],
    [1360105200000, 18.40],
    [1360191600000, 18.07],
    [1360278000000, 19.12],
    [1360537200000, 18.64],
    [1360623600000, 18.89],
    [1360710000000, 18.81],
    [1360796400000, 18.61],
    [1360882800000, 18.63],
    [1361228400000, 18.99],
    [1361314800000, 18.77],
    [1361401200000, 18.34],
    [1361487600000, 18.55],
    [1361746800000, 18.11],
    [1361833200000, 18.59],
    [1361919600000, 19.60],
]

var data2 = [
    [1327359600000, 10.95],
    [1327446000000, 11.34],
    [1327532400000, 11.18],
    [1327618800000, 11.05],
    [1327878000000, 11.00],
    [1327964400000, 10.95],
    [1328050800000, 11.24],
    [1328137200000, 11.29],
    [1328223600000, 11.85],
    [1328482800000, 11.86],
    [1328569200000, 12.28],
    [1328655600000, 12.10],
    [1328742000000, 12.65],
    [1328828400000, 12.21],
    [1329087600000, 12.35],
    [1329174000000, 12.44],
    [1329260400000, 12.46],
    [1329346800000, 12.86],
    [1329433200000, 12.75],
    [1329778800000, 12.54],
    [1329865200000, 12.33],
    [1329951600000, 12.97],
    [1330038000000, 13.41],
    [1330297200000, 13.27],
    [1330383600000, 13.27],
    [1330470000000, 12.89],
    [1330556400000, 13.10],
    [1330642800000, 13.73],
    [1330902000000, 13.22],
    [1330988400000, 11.99],
    [1331074800000, 12.41],
    [1331161200000, 13.05],
    [1331247600000, 13.64],
    [1331506800000, 13.56],
    [1331593200000, 14.22],
    [1331679600000, 13.77],
    [1331766000000, 14.17],
    [1331852400000, 13.82],
    [1332111600000, 14.51],
    [1332198000000, 13.16],
    [1332284400000, 13.56],
    [1332370800000, 13.71],
    [1332457200000, 13.81],
    [1332712800000, 14.40],
    [1332799200000, 14.63],
    [1332885600000, 14.46],
    [1332972000000, 14.48],
    [1333058400000, 14.31],
    [1333317600000, 14.70],
    [1333404000000, 14.31],
    [1333490400000, 13.46],
    [1333576800000, 13.59],
    [1333922400000, 13.22],
    [1334008800000, 12.61],
    [1334095200000, 13.01],
    [1334181600000, 13.55],
    [1334268000000, 13.18],
    [1334527200000, 12.84],
    [1334613600000, 13.84],
    [1334700000000, 13.39],
    [1334786400000, 12.91],
    [1334872800000, 13.06],
    [1335132000000, 12.62],
    [1335218400000, 12.40],
    [1335304800000, 13.13],
    [1335391200000, 13.26],
    [1335477600000, 13.58],
    [1335736800000, 13.55],
    [1335823200000, 13.77],
    [1335909600000, 13.76],
    [1335996000000, 13.32],
    [1336082400000, 12.61],
    [1336341600000, 12.52],
    [1336428000000, 12.67],
    [1336514400000, 12.52],
    [1336600800000, 11.92],
    [1336687200000, 12.20],
    [1336946400000, 12.23],
    [1337032800000, 12.33],
    [1337119200000, 12.36],
    [1337205600000, 12.01],
    [1337292000000, 11.31],
    [1337551200000, 12.01],
    [1337637600000, 12.01],
    [1337724000000, 12.18],
    [1337810400000, 11.54],
    [1337896800000, 11.60],
    [1338242400000, 12.05],
    [1338328800000, 11.29],
    [1338415200000, 11.05],
    [1338501600000, 19.82],
    [1338760800000, 10.31],
    [1338847200000, 10.70],
    [1338933600000, 11.69],
    [1339020000000, 11.32],
    [1339106400000, 11.65],
    [1339365600000, 11.13],
    [1339452000000, 11.77],
    [1339538400000, 11.79],
    [1339624800000, 11.67],
    [1339711200000, 12.39],
    [1339970400000, 12.63],
    [1340056800000, 12.89],
    [1340143200000, 11.99],
    [1340229600000, 11.23],
    [1340316000000, 11.57],
    [1340575200000, 10.84],
    [1340661600000, 11.07],
    [1340748000000, 11.41],
    [1340834400000, 11.17],
    [1340920800000, 12.37],
    [1341180000000, 12.19],
    [1341266400000, 12.51],
    [1341439200000, 12.53],
    [1341525600000, 11.37],
    [1341784800000, 10.43],
    [1341871200000, 10.44],
    [1341957600000, 10.20],
    [1342044000000, 10.14],
    [1342130400000, 10.65],
    [1342389600000, 10.40],
    [1342476000000, 10.65],
    [1342562400000, 11.43],
    [1342648800000, 11.89],
    [1342735200000, 11.38],
    [1342994400000, 10.64],
    [1343080800000, 10.02],
    [1343167200000, 10.33],
    [1343253600000, 10.95],
    [1343340000000, 11.89],
    [1343599200000, 11.01],
    [1343685600000, 10.88],
    [1343772000000, 10.69],
    [1343858400000, 10.58],
    [1343944800000, 12.02],
    [1344204000000, 12.14],
    [1344290400000, 12.37],
    [1344376800000, 12.51],
    [1344463200000, 12.65],
    [1344549600000, 12.64],
    [1344808800000, 12.27],
    [1344895200000, 12.10],
    [1344981600000, 12.91],
    [1345068000000, 13.65],
    [1345154400000, 13.80],
    [1345413600000, 13.92],
    [1345500000000, 13.75],
    [1345586400000, 13.84],
    [1345672800000, 13.50],
    [1345759200000, 12.26],
    [1346018400000, 12.32],
    [1346104800000, 12.06],
    [1346191200000, 11.96],
    [1346277600000, 11.46],
    [1346364000000, 11.27],
    [1346709600000, 11.43],
    [1346796000000, 12.26],
    [1346882400000, 12.79],
    [1346968800000, 12.46],
    [1347228000000, 12.13],
    [1347314400000, 12.43],
    [1347400800000, 12.42],
    [1347487200000, 12.81],
    [1347573600000, 13.34],
    [1347832800000, 13.41],
    [1347919200000, 12.57],
    [1348005600000, 13.12],
    [1348092000000, 14.53],
    [1348178400000, 13.83],
    [1348437600000, 13.41],
    [1348524000000, 12.90],
    [1348610400000, 12.53],
    [1348696800000, 12.80],
    [1348783200000, 12.44],
    [1349042400000, 12.62],
    [1349128800000, 12.57],
    [1349215200000, 12.60],
    [1349301600000, 12.68],
    [1349388000000, 12.47],
    [1349647200000, 12.23],
    [1349733600000, 11.68],
    [1349820000000, 11.51],
    [1349906400000, 11.78],
    [1349992800000, 11.94],
    [1350252000000, 12.33],
    [1350338400000, 13.24],
    [1350424800000, 13.44],
    [1350511200000, 13.48],
    [1350597600000, 13.24],
    [1350856800000, 13.49],
    [1350943200000, 13.31],
    [1351029600000, 13.36],
    [1351116000000, 13.40],
    [1351202400000, 14.01],
    [1351638000000, 14.02],
    [1351724400000, 14.36],
    [1351810800000, 14.39],
    [1352070000000, 14.24],
    [1352156400000, 14.39],
    [1352242800000, 13.47],
    [1352329200000, 12.98],
    [1352415600000, 12.90],
    [1352674800000, 12.70],
    [1352761200000, 12.54],
    [1352847600000, 12.23],
    [1352934000000, 12.64],
    [1353020400000, 12.65],
    [1353279600000, 12.92],
    [1353366000000, 12.64],
    [1353452400000, 12.84],
    [1353625200000, 13.40],
    [1353884400000, 13.30],
    [1353970800000, 13.18],
    [1354057200000, 13.88],
    [1354143600000, 14.09],
    [1354230000000, 14.61],
    [1354489200000, 14.70],
    [1354575600000, 15.30],
    [1354662000000, 15.40],
    [1354748400000, 15.14],
    [1354834800000, 15.48],
    [1355094000000, 15.75],
    [1355180400000, 15.54],
    [1355266800000, 15.96],
    [1355353200000, 15.53],
    [1355439600000, 17.56],
    [1355698800000, 17.42],
    [1355785200000, 17.49],
    [1355871600000, 18.09],
    [1355958000000, 17.87],
    [1356044400000, 17.71],
    [1356303600000, 17.53],
    [1356476400000, 17.55],
    [1356562800000, 17.30],
    [1356649200000, 16.90],
    [1356908400000, 17.68],
    [1357081200000, 18.34],
    [1357167600000, 17.75],
    [1357254000000, 18.13],
    [1357513200000, 17.94],
    [1357599600000, 18.14],
    [1357686000000, 18.66],
    [1357772400000, 18.62],
    [1357858800000, 18.09],
    [1358118000000, 18.16],
    [1358204400000, 18.15],
    [1358290800000, 17.88],
    [1358377200000, 17.73],
    [1358463600000, 17.98],
    [1358809200000, 17.95],
    [1358895600000, 18.25],
    [1358982000000, 18.10],
    [1359068400000, 18.32],
    [1359327600000, 18.24],
    [1359414000000, 18.52],
    [1359500400000, 17.94],
    [1359586800000, 17.83],
    [1359673200000, 18.34],
    [1359932400000, 18.10],
    [1360018800000, 18.51],
    [1360105200000, 18.40],
    [1360191600000, 18.07],
    [1360278000000, 19.12],
    [1360537200000, 18.64],
    [1360623600000, 18.89],
    [1360710000000, 18.81],
    [1360796400000, 18.61],
    [1360882800000, 18.63],
    [1361228400000, 18.99],
    [1361314800000, 18.77],
    [1361401200000, 18.34],
    [1361487600000, 18.55],
    [1361746800000, 18.11],
    [1361833200000, 18.59],
    [1361919600000, 19.60],
]



// class AreaChart extends React.Component {
//     constructor(props) {
//         super(props);

//         this.state = {

//             series: [{
//                 name: "Desktops",
//                 data: [10, 41, 35, 51, 49, 62, 69, 91, 80, 10, 41, 35, 51, 49, 62, 69, 91, 80]
//             }],
//             options: {
//                 chart: {
//                     height: 100,
//                     type: 'line',
//                     zoom: {
//                         enabled: false
//                     },

//                     toolbar: {
//                         show: false,
//                     }
//                 },
//                 dataLabels: {
//                     enabled: false
//                 },
//                 stroke: {
//                     curve: 'smooth',
//                     width: 2,
//                     colors: ["#F7931A"],
//                 },
//                 grid: {
//                     show: false,
//                 },
//                 tooltip: {
//                     enabled: false,
//                     x: {
//                         format: "dd MMM yyyy"
//                     },
//                 },
//                 xaxis: {
//                     categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
//                     axisBorder: {
//                         show: false
//                     },

//                     labels: {
//                         show: false
//                     }
//                 },
//                 yaxis: {
//                     labels: {
//                         show: false
//                     }
//                 },
//             },


//         };
//     }



//     render() {
//         return (


//             <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={100} />


//         );
//     }
// }


class AreaChart extends Component {
    constructor(props) {
        super(props);

        this.state = {

            series: [{
                name: 'Buy',
                data: data1
            }, {
                name: 'Sell',
                data: data2
            }],
            options: {
                chart: {
                    type: "area",
                    height: 300,
                    foreColor: "#8C87C2",
                    fontFamily: 'Rubik, sans-serif',
                    stacked: true,
                    dropShadow: {
                        enabled: true,
                        enabledSeries: [0],
                        top: -2,
                        left: 2,
                        blur: 5,
                        opacity: 0.06
                    },
                    toolbar: {
                        show: false,
                    }
                },
                colors: ['#7B6FFF', '#1652F0'],
                stroke: {
                    curve: "smooth",
                    width: 3
                },
                dataLabels: {
                    enabled: false
                },

                markers: {
                    size: 0,
                    strokeColor: "#fff",
                    strokeWidth: 3,
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    hover: {
                        size: 6
                    }
                },
                xaxis: {
                    type: "datetime",
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        offsetX: -10,
                        offsetY: 0
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                grid: {
                    show: false,
                    padding: {
                        left: -5,
                        right: 5
                    }
                },
                tooltip: {
                    x: {
                        format: "dd MMM yyyy"
                    },
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 100, 100]
                    }
                },
            },


            selection: 'one_year',

        };
    }


    updateData(timeline) {
        this.setState({
            selection: timeline
        })

        switch (timeline) {
            case 'one_month':
                this.setState({
                    options: {
                        xaxis: {
                            min: new Date('28 Jan 2013').getTime(),
                            max: new Date('27 Feb 2013').getTime(),
                        }
                    }
                })
                break;
            case 'six_months':
                this.setState({
                    options: {
                        xaxis: {
                            min: new Date('27 Sep 2012').getTime(),
                            max: new Date('27 Feb 2013').getTime(),
                        }
                    }
                })
                break;
            case 'one_year':
                this.setState({
                    options: {
                        xaxis: {
                            min: new Date('27 Feb 2012').getTime(),
                            max: new Date('27 Feb 2013').getTime(),
                        }
                    }
                })
                break;
            case 'ytd':
                this.setState({
                    options: {
                        xaxis: {
                            min: new Date('01 Jan 2013').getTime(),
                            max: new Date('27 Feb 2013').getTime(),
                        }
                    }
                })
                break;
            case 'all':
                this.setState({
                    options: {
                        xaxis: {
                            min: undefined,
                            max: undefined,
                        }
                    }
                })
                break;
            default:

        }
    }


    render() {
        return (


            <>
                <div className="duration-option">
                    <Link id="one_month"

                        onClick={() => this.updateData('one_month')} className={(this.state.selection === 'one_month' ? 'active' : '')}>
                        1M
    </Link>
                    &nbsp;
    <Link id="six_months"

                        onClick={() => this.updateData('six_months')} className={(this.state.selection === 'six_months' ? 'active' : '')}>
                        6M
    </Link>
                    &nbsp;
    <Link id="one_year"


                        onClick={() => this.updateData('one_year')} className={(this.state.selection === 'one_year' ? 'active' : '')}>
                        1Y
    </Link>
                    &nbsp;
    <Link id="ytd"

                        onClick={() => this.updateData('ytd')} className={(this.state.selection === 'ytd' ? 'active' : '')}>
                        YTD
    </Link>
                    &nbsp;
    <Link id="all"

                        onClick={() => this.updateData('all')} className={(this.state.selection === 'all' ? 'active' : '')}>
                        ALL
    </Link>
                </div>

                <div id="chart-timeline">
                    <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
                </div>
            </>


        );
    }
}

export default AreaChart;

// function generateDayWiseTimeSeries(s, count) {
//     var values = [[
//         274, 248, 273, 48, 285, 229, 69, 51, 16, 141, 388, 325, 333, 220, 133, 376, 213, 97, 90, 155, 172, 205, 117, 16, 247, 85, 392, 66, 278, 373, 224, 37, 139, 89, 280, 150, 134, 325, 195, 279, 242, 62, 72, 239, 131, 376, 71, 231, 148, 264, 214, 300, 180, 126, 67, 150, 114, 258, 379, 176, 310, 47, 371, 29, 311, 90, 170, 173, 237, 281, 93, 33, 384, 189, 180, 179, 203, 220, 1, 299, 203, 393, 227, 381, 225, 71, 219, 312, 342, 338, 247, 293, 374, 295, 390, 120, 137, 109, 28, 45, 137, 218, 26, 24, 234, 192, 130, 102, 260, 112, 349, 84, 7, 161, 331, 179, 190, 72, 41, 108, 329, 331, 157, 43, 260, 128, 264, 229, 264, 181, 314, 397, 167, 137, 111, 386, 158, 242, 235, 126, 83, 374, 149, 395, 124, 225, 246, 16, 326, 90, 66, 167, 144, 57, 293, 137, 102, 260, 136, 129, 25, 249, 183, 263, 274, 357, 275, 335, 221, 344, 226, 5, 251, 28, 214, 67, 189, 243, 251, 112, 326, 277, 53, 262, 18, 353, 112, 96, 237, 320, 129, 309, 285, 306, 103, 214, 296, 380, 177, 389, 195, 343, 232, 136, 348, 39, 341, 148, 171, 176, 325, 380, 134, 389, 398, 288, 217, 257, 388, 17, 24, 364, 380, 169, 267, 220, 180, 127, 249, 165, 243, 2, 95, 319, 355, 327, 35, 84, 225, 260, 166, 270, 185, 148, 174, 276, 208, 175, 355, 40, 176, 348, 206, 273, 286, 363, 196, 68, 37, 136, 40, 182, 150, 193, 203, 219, 265, 328, 203, 361, 281, 188, 144, 351, 98, 31, 394, 340, 53, 367, 326, 316, 114, 358, 374, 151, 367, 167, 82, 230, 14, 98, 223, 118, 89, 279, 312, 61, 317, 38, 135, 75, 42, 338, 296, 256, 263, 381, 221, 112, 175, 77, 151, 367, 108, 337, 297, 185, 309, 286, 133, 41, 269, 358, 222, 351, 321, 302, 310, 89, 199, 309, 79, 26, 78, 290, 162, 290, 145, 88, 32, 243, 27, 206, 11, 100, 189, 317, 1, 9, 96, 9, 195, 390, 141, 20, 90, 64, 118, 262, 246, 42, 394, 93, 138, 283, 232, 208, 249, 95
//     ],
//     [
//         133, 327, 59, 94, 62, 378, 229, 98, 103, 256, 257, 315, 118, 114, 295, 118, 142, 301, 256, 101, 87, 289, 43, 287, 173, 350, 154, 199, 224, 44, 155, 111, 97, 344, 334, 5, 96, 306, 60, 62, 39, 373, 27, 196, 316, 362, 31, 339, 180, 97, 71, 235, 45, 93, 76, 261, 170, 151, 140, 198, 197, 69, 2, 291, 272, 42, 229, 140, 179, 350, 129, 254, 315, 315, 6, 341, 6, 344, 231, 13, 65, 300, 14, 331, 311, 110, 85, 5, 191, 273, 146, 200, 171, 190, 125, 387, 4, 104, 167, 366, 4, 325, 168, 118, 396, 24, 161, 385, 167, 345, 41, 2, 86, 82, 258, 397, 317, 58, 159, 252, 254, 200, 114, 64, 376, 37, 90, 87, 98, 35, 304, 118, 194, 199, 146, 100, 171, 302, 159, 361, 158, 331, 374, 268, 155, 310, 72, 74, 28, 18, 120, 154, 93, 77, 362, 121, 241, 297, 267, 24, 92, 61, 21, 123, 275, 79, 261, 201, 100, 146, 177, 50, 105, 80, 15, 333, 234, 47, 306, 75, 95, 243, 283, 150, 13, 237, 82, 46, 69, 182, 87, 109, 203, 264, 349, 323, 5, 350, 122, 75, 259, 355, 351, 175, 44, 265, 27, 231, 272, 102, 111, 178, 318, 360, 190, 132, 51, 347, 14, 130, 47, 41, 67, 308, 205, 366, 217, 94, 157, 218, 86, 358, 391, 300, 184, 306, 222, 54, 361, 18, 236, 310, 167, 27, 150, 192, 386, 298, 388, 282, 59, 220, 44, 276, 87, 160, 179, 85, 223, 260, 106, 125, 326, 332, 149, 274, 73, 138, 85, 144, 205, 281, 253, 282, 6, 47, 37, 244, 254, 59, 277, 257, 302, 236, 398, 72, 358, 224, 395, 310, 43, 398, 201, 275, 284, 240, 117, 335, 340, 130, 61, 185, 43, 101, 34, 351, 363, 85, 185, 256, 31, 369, 372, 328, 48, 121, 201, 20, 173, 232, 218, 387, 86, 231, 35, 323, 283, 391, 331, 99, 203, 204, 275, 397, 72, 151, 52, 385, 398, 256, 94, 92, 75, 75, 247, 56, 340, 103, 379, 327, 297, 287, 103, 337, 181, 90, 160, 57, 70, 170, 46, 175, 35, 301, 196, 340, 130, 172, 289, 221
//     ]];
//     var i = 0;
//     var series = [];
//     var x = new Date("19 Feb 2020").getTime();
//     while (i < count) {
//         series.push([x, values[s][i]]);
//         x += 13273596;
//         i++;
//     }
//     return series;
// }